import locale38517862 from '../../locales/ar.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ar","silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.json","dir":"ltr","name":"English","flag":"/images/locales/en.svg"},{"code":"ar","iso":"ar-JO","file":"ar.json","dir":"rtl","name":"عربي","flag":"/images/locales/ar.svg"}],
  defaultLocale: "ar",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: {"skipNuxtState":false},
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.json","dir":"ltr","name":"English","flag":"/images/locales/en.svg"},{"code":"ar","iso":"ar-JO","file":"ar.json","dir":"rtl","name":"عربي","flag":"/images/locales/ar.svg"}],
  localeCodes: ["en","ar"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "a",
  34: "r",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "a",
  55: "r",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: "}",
}

export const localeMessages = {
  'ar.json': () => Promise.resolve(locale38517862),
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
}
