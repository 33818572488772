
import { mapGetters } from "vuex"
import config from "~/mixins/config"
export default {
	name: "WebsiteLayout",
	mixins: [config],
	data() {
		return {
			// systemBarHeight: 0,
			// mainBarHeight: 72,
			hoveredItem: null,
			isCategoriesMenuActive: false,
			mobileMainMenuModel: false,
			mobileSearchMenuModel: false,
			height: 42,
			pages: [
				{
					slug: "privacy-policy",
					name: this.$t("common.privacy-policy"),
				},
				{
					slug: "website-use-policy",
					name: this.$t("common.website-use-policy"),
				},
				{
					slug: "terms-of-wallet",
					name: this.$t("common.terms-of-wallet"),
				},
				{
					slug: "terms-of-service",
					name: this.$t("common.terms-of-service"),
				},
				{
					slug: "blog",
					name: this.$t("common.blog"),
				},
			],
			links: [
				{
					to: this.localePath({
						name: "branch",
					}),
					text: this.$t("common.branches"),
				},

				{
					to: this.localePath({
						name: "contact",
					}),
					text: this.$t("common.connect-us"),
				},
				{
					slug: "about-us",
					name: this.$t("common.about-us"),
				},
			],

			socials: [
				{
					url: "https://www.facebook.com/Actionmobile11?locale=ar_AR",
					icon: "mdi-facebook",
				},
				{
					url: "https://x.com/actionwebsite?s=11",
					icon: "mdi-twitter",
				},
				{
					url: "https://www.instagram.com/actionwebsite/",
					icon: "mdi-instagram",
				},
				{
					url: "https://t.snapchat.com/a2P8N1QR",
					icon: "mdi-snapchat",
				},
			],
		}
	},
	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
		}
	},
	// async fetch() {
	//     await this.$axios.$get(`/v1/pages`).then((resp) => {
	//         this.pages = resp;
	//     });
	// },
	// async fetch() {
	// 	await this.$axios.$get(`/v1/categroies`).then((resp) => {
	// 		this.categroies = resp;
	// 	});
	// },

	// async fetch() {
	// 	this.products = this.pagination.perPage;

	// 	const query = {
	// 		perPage: this.pagination.perPage,
	// 	};
	// 	const promises = [];

	// 	promises.push(
	// 		this.$axios
	// 			.$get(`v1/my/wishlist`, {
	// 				params: {
	// 					...this.$route.query,
	// 					...query,
	// 				},
	// 			})
	// 			.then((resp) => {
	// 				this.products = resp.items;
	// 				this.pagination = resp.pagination;
	// 			})
	// 	);

	// 	await Promise.all(promises).catch((error) => {
	// 		this.genericErrorHandler(error);
	// 	});
	// },
	computed: {
		availableLocales() {
			return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
		},
		categories() {
			return this.$store.state.categories
		},
		baseURI() {
			return process.env.NUXT_ENV_BASE_URL || 'https://action.jo'
		},
		...mapGetters({ wishlistItems: "wishlist/wishlistItems" }),
	},
	created() {
		this.$vuetify.rtl = this.$i18n.localeProperties.dir === "rtl"
	},
	mounted() {
		this.$store.dispatch("wishlist/get")
		window.addEventListener(
			"keydown",
			function (e) {
				// console.log(e);
				if (e.key === "F8") {
					/* eslint-disable-next-line */
					debugger;
				}
			},
			false,
		)

		this.adjustHeader()

		window.addEventListener("resize", this.adjustHeader)
	},
	methods: {
		adjustHeader() {
			this.$nextTick(() => {
				if (this.isMobile) {
					this.height = 72
				}
			})
		},
	},
}
