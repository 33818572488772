
export default {
	name: "EmptyLayout",
	layout: "base",
	props: {
		error: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			pageNotFound: "Page Not Found",
			otherError: "An error occurred",
		}
	},
	head() {
		const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
		return {
			title,
			htmlAttrs: {
				lang: this.$i18n.locale,
			},
		}
	},
	computed: {
		msg() {
			switch (this.error.statusCode) {
				case 404:
					return this.pageNotFound
				default:
					return this.otherError
			}
		},
	},
}
