import { render, staticRenderFns } from "./WishlistDrawer.vue?vue&type=template&id=68917403&"
import script from "./WishlistDrawer.vue?vue&type=script&lang=js&"
export * from "./WishlistDrawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Thumbnail: require('/app/components/Thumbnail.vue').default,NoWishlistItems: require('/app/components/NoWishlistItems.vue').default})
